export const COGNITO = {
  REGION: "eu-west-2",
  USER_POOL_ID: "eu-west-2_Yehc8zOKO",
  APP_CLIENT_ID: "770mrll611uvjdprrgltpmk6vf",
};

export const API = {
  API_BASE_URL:
    process.env.NODE_ENV === "production"
      ? "https://un8ue69gq0.execute-api.eu-west-2.amazonaws.com/dev"
      : "http://localhost:3000/dev",
};
